//@author: devin
//Migrated by: travis
//@lastmodified: 2021-12-08

import { Item } from "../../pojo/Item";
import { S25QLTokenizer, Tokenizer } from "./s25ql.tokenizer";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Proto } from "../../pojo/Proto";
import { DropDownItem } from "../../pojo/DropDownItem";
import Ids = Item.Ids;
import Option = QLTerm.Option;
import NumericalString = Proto.NumericalString;
import StepType = SearchCriteria.StepType;
import Step = SearchCriteria.Step;
import QLItemTypes = Tokenizer.QLItemTypes;

const { Event, Task, Location, Resource, Organization } = SearchCriteria.StepType;

export namespace QLTerm {
    import Step = SearchCriteria.Step;
    export type Data = {
        stepTypeId: number;
        subStepTypeId?: number;
        type: keyof typeof S25QLTokenizer.parseValueMap;
        modelValueParameter?: string; // Used for translation to model
        modelValueComparator?: string; // Used for translation to model
        static?: boolean; // Some terms have pre-defined model values
        operations: Operation[];
        suggest: Suggest;
        options?: Option[];
        objectType?: SearchCriteriaType["type"];
        searchType?: Item.Ids;
        rangeParameters?: { start: string; end: string }; // Used for translation to model
        skipIf?: (step: Step) => boolean;
        isComposed?: boolean; // Whether one step is composed of multiple terms
    };

    export type Operation =
        | "="
        | ">"
        | "<"
        | ">="
        | "<="
        | "("
        | ")"
        | "in"
        | "notIn"
        | "all"
        | "contains"
        | "startsWith"
        | "endsWith"
        | "between"
        | "+"
        | "-";

    export type Suggest =
        | "none"
        | "option"
        | "options"
        | "object"
        | "objects"
        | "search"
        | "contactRole"
        | "attribute"
        | "relationship"
        | "address";

    export type Option = { label: string; value?: any };
}

const BooleanOptions: Option[] = [{ label: "true", value: "T" }]; // False is not currently supported on the back end

const Terms2: Record<QLItemTypes, Record<string, QLTerm.Data>> = {
    [Item.Ids.Event]: {
        isFav: {
            stepTypeId: Event.Favorites,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            modelValueParameter: "favorites",
            static: true,
        },
        name: {
            stepTypeId: Event.Details,
            subStepTypeId: 1,
            type: "string",
            operations: ["=", "startsWith", "endsWith", "contains"],
            suggest: "none",
            modelValueParameter: "itemName",
            modelValueComparator: "searchMethod",
            isComposed: true, // Details are one step
        },
        title: {
            stepTypeId: Event.Details,
            subStepTypeId: 2,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "event_title",
            modelValueComparator: "searchMethod",
            isComposed: true, // Details are one step
        },
        ref: {
            stepTypeId: Event.Details,
            subStepTypeId: 3,
            type: "string",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "reference_number",
            isComposed: true, // Details are one step
        },
        structure: {
            stepTypeId: Event.Details,
            subStepTypeId: 4,
            type: "string",
            operations: ["="],
            suggest: "option",
            options: [
                { label: "Event", value: "E" },
                { label: "Folder", value: "F" },
                { label: "Cabinet", value: "C" },
            ],
            modelValueParameter: "event_structure_element_type",
            isComposed: true, // Details are one step
        },
        earliestStart: {
            stepTypeId: Event.Details,
            subStepTypeId: 5,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "start_dt",
            isComposed: true, // Details are one step
        },
        latestEnd: {
            stepTypeId: Event.Details,
            subStepTypeId: 6,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "end_dt",
            isComposed: true, // Details are one step
        },
        modifiedSince: {
            stepTypeId: Event.Details,
            subStepTypeId: 7,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "modified_since",
            isComposed: true, // Details are one step
        },
        modifiedBy: {
            stepTypeId: Event.Details,
            subStepTypeId: 8,
            type: "string",
            operations: ["="],
            suggest: "object",
            objectType: "contacts",
            modelValueParameter: "last_modified_by",
            isComposed: true, // Details are one step
        },
        alienUID: {
            stepTypeId: Event.Details,
            subStepTypeId: 9,
            type: "string",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "alien_uid",
            isComposed: true, // Details are one step
        },
        cabinet: {
            stepTypeId: Event.Cabinets,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "eventCabinets",
        },
        folder: {
            stepTypeId: Event.Folders,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "eventFolders",
        },
        type: {
            stepTypeId: Event.Types,
            type: "stringOrNumberList",
            operations: ["in", "notIn"],
            suggest: "objects",
            objectType: "eventTypes",
        },
        state: {
            stepTypeId: Event.States,
            type: "stringList",
            operations: ["in"],
            suggest: "objects",
            objectType: "eventStates",
        },
        keyword: {
            stepTypeId: Event.Keyword,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "itemName",
        },
        org: {
            stepTypeId: Event.Organizations,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "organizations",
        },
        contact: {
            stepTypeId: Event.Contacts,
            type: "embedded",
            operations: ["in"],
            suggest: "contactRole",
        },
        event: {
            stepTypeId: Event.Events,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "events",
        },
        category: {
            stepTypeId: Event.Categories,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "eventCategories",
        },
        requirement: {
            stepTypeId: Event.Requirements,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "eventRequirements",
        },
        location: {
            stepTypeId: Event.AssignedSpaces,
            type: "stringOrNumberList",
            operations: ["in", "all"],
            suggest: "objects",
            objectType: "locations",
        },
        locationPreferences: {
            stepTypeId: Event.SpacePreferences,
            type: "stringOrNumberList",
            operations: ["in", "all"],
            suggest: "objects",
            objectType: "locations",
        },
        resource: {
            stepTypeId: Event.AssignedResources,
            type: "stringOrNumberList",
            operations: ["in", "all"],
            suggest: "objects",
            objectType: "resources",
        },
        eventSearch: {
            stepTypeId: Event.EventSearch,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Event,
            modelValueParameter: "itemName",
        },
        eventExcludeSearch: {
            stepTypeId: Event.EventSearchExclude,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Event,
            modelValueParameter: "itemName",
        },
        orgSearch: {
            stepTypeId: Event.SponsoringOrganizations,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Organization,
            modelValueParameter: "itemName",
        },
        resourceSearch: {
            stepTypeId: Event.ResourcesAssignments,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Resource,
            modelValueParameter: "itemName",
        },
        locationSearch: {
            stepTypeId: Event.SpaceAssignments,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Location,
            modelValueParameter: "itemName",
        },
        hasRelated: {
            stepTypeId: Event.IncludeRelatedEvents,
            subStepTypeId: 1,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            static: true,
            skipIf: (step: Step) => step?.step_param?.[0]?.related_event_type_id !== 11,
        },
        hasBound: {
            stepTypeId: Event.IncludeRelatedEvents,
            subStepTypeId: 2,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            static: true,
            skipIf: (step: Step) => step?.step_param?.[0]?.related_event_type_id !== 12,
        },
        vCalNeedsLocation: {
            stepTypeId: Event.OutstandingNeedsSpaceVCalendarToDos,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            static: true,
        },
        vCalExceedsHeadCount: {
            stepTypeId: Event.OutstandingExceedsHeadcountVCalendarToDos,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            static: true,
        },
        vCalExport: {
            stepTypeId: Event.OutstandingExportVCalendarToDos,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            static: true,
        },
        occDate: {
            stepTypeId: Event.Occurrences,
            type: "dateRange",
            operations: ["between"],
            suggest: "none",
            rangeParameters: { start: "from_dt", end: "until_dt" },
            isComposed: true, // occDate, occTime, daysOfWeek are one step
        },
        occTime: {
            stepTypeId: Event.Occurrences,
            type: "timeRange",
            operations: ["between"],
            suggest: "none",
            rangeParameters: { start: "occurrence_start_dt", end: "occurrence_end_dt" },
            isComposed: true, // occDate, occTime, daysOfWeek are one step
        },
        daysOfWeek: {
            stepTypeId: Event.Occurrences,
            type: "stringList",
            operations: ["in"],
            suggest: "options",
            options: [
                { label: "M", value: "monday" },
                { label: "T", value: "tuesday" },
                { label: "W", value: "wednesday" },
                { label: "R", value: "thursday" },
                { label: "F", value: "friday" },
                { label: "S", value: "saturday" },
                { label: "U", value: "sunday" },
            ],
            isComposed: true, // occDate, occTime, daysOfWeek are one step
        },
        expected: {
            stepTypeId: Event.ReservationDetails,
            subStepTypeId: 1,
            type: "numberRange",
            operations: ["between"],
            suggest: "none",
            rangeParameters: { start: "expected_head_count_gte", end: "expected_head_count_lte" },
            isComposed: true, // expected, registered, are one step
        },
        registered: {
            stepTypeId: Event.ReservationDetails,
            subStepTypeId: 2,
            type: "numberRange",
            operations: ["between"],
            suggest: "none",
            rangeParameters: { start: "registered_head_count_gte", end: "registered_head_count_lte" },
            isComposed: true, // expected, registered, are one step
        },
        segmentName: {
            stepTypeId: Event.ReservationDetails,
            subStepTypeId: 3,
            type: "string",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "reservation_name",
        },
        createDt: {
            stepTypeId: Event.CreatedDate,
            type: "date",
            operations: [">="],
            suggest: "none",
            modelValueParameter: "from_dt",
            isComposed: true, // createDt, createTime, are one step
        },
        createTime: {
            stepTypeId: Event.CreatedDate,
            type: "time",
            operations: [">="],
            suggest: "none",
            modelValueParameter: "start_time",
            isComposed: true, // createDt, createTime, are one step
        },
        missingLocationAssn: {
            stepTypeId: Event.MissingSpaceAssignments,
            type: "dateRange",
            operations: ["between"],
            suggest: "none",
            rangeParameters: { start: "from_dt", end: "until_dt" },
        },
        custAtrb: {
            stepTypeId: Event.CustomAttributes,
            type: "customAttribute",
            operations: ["in"],
            suggest: "attribute",
        },
        profileCode: {
            stepTypeId: Event.ProfileCode,
            type: "profileCode",
            operations: ["in", "notIn"],
            suggest: "none",
            modelValueParameter: "profile_code",
        },
        standardSchedule: {
            stepTypeId: Event.StandardSchedule,
            type: "stringOrNumberList",
            objectType: "standardSchedules",
            operations: ["=", "in"],
            suggest: "objects",
            modelValueParameter: "standard_schedule",
        },
    },

    [Item.Ids.Location]: {
        isFav: {
            stepTypeId: Location.Favorites,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            modelValueParameter: "favorites",
            static: true,
        },
        name: {
            stepTypeId: Location.Details,
            subStepTypeId: 1,
            type: "string",
            operations: ["=", "startsWith", "endsWith", "contains"],
            suggest: "none",
            modelValueParameter: "itemName",
            modelValueComparator: "searchMethod",
            isComposed: true, // Details are one step
        },
        formal: {
            stepTypeId: Location.Details,
            subStepTypeId: 2,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "formal_name",
            modelValueComparator: "formal_name_search_method",
            isComposed: true, // Details are one step
        },
        keyword: {
            stepTypeId: Location.Keyword,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "itemName",
        },
        maxCap: {
            stepTypeId: Location.Details,
            subStepTypeId: 3,
            type: "number",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "max_capacity",
            isComposed: true, // Details are one step
        },
        minCap: {
            stepTypeId: Location.Details,
            subStepTypeId: 4,
            type: "number",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "min_capacity",
            isComposed: true, // Details are one step
        },
        fill: {
            stepTypeId: Location.Details,
            subStepTypeId: 5,
            type: "number",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "fill_ratio",
            isComposed: true, // Details are one step
        },
        modifiedSince: {
            stepTypeId: Location.Details,
            subStepTypeId: 6,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "modified_since",
            isComposed: true, // Details are one step
        },
        modifiedBy: {
            stepTypeId: Location.Details,
            subStepTypeId: 7,
            type: "string",
            operations: ["="],
            suggest: "object",
            objectType: "contacts",
            modelValueParameter: "last_modified_by",
            isComposed: true, // Details are one step
        },
        location: {
            stepTypeId: Location.Spaces,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "locations",
        },
        locationSearch: {
            stepTypeId: Location.Search,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Location,
            modelValueParameter: "itemName",
        },
        building: {
            stepTypeId: Location.Buildings,
            type: "stringOrNumberList",
            operations: ["in", "notIn"],
            suggest: "objects",
            objectType: "buildings",
        },
        partition: {
            stepTypeId: Location.Partitions,
            type: "stringOrNumberList",
            operations: ["in", "notIn"],
            suggest: "objects",
            objectType: "locationPartitions",
        },
        feature: {
            stepTypeId: Location.Features,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "locationFeatures",
        },
        category: {
            stepTypeId: Location.Categories,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "locationCategories",
        },
        layout: {
            stepTypeId: Location.Layouts,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "locationLayouts",
        },
        relationship: {
            stepTypeId: Location.SpaceRelationships,
            type: "embedded",
            operations: ["in", "notIn", "all"],
            suggest: "relationship",
        },
        custAtrb: {
            stepTypeId: Location.CustomAttributes,
            type: "customAttribute",
            operations: ["in"],
            suggest: "attribute",
        },
        event: {
            stepTypeId: Location.AssignedSpaces,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "events",
        },
        eventSearch: {
            stepTypeId: Location.SpaceAssignments,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Event,
            modelValueParameter: "itemName",
        },
        includeRelated: {
            stepTypeId: Location.IncludeRelatedSpaces,
            type: "stringList",
            operations: ["in"],
            suggest: "options",
            options: [
                { label: "Also Assign", value: 4 },
                { label: "Blocked By", value: 3 },
                { label: "Close To", value: 2 },
                { label: "Divides Into", value: 5 },
                { label: "Subdivision Of", value: 1 },
            ],
        },
    },

    [Item.Ids.Resource]: {
        isFav: {
            stepTypeId: Resource.Favorites,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            modelValueParameter: "favorites",
            static: true,
        },
        name: {
            stepTypeId: Resource.Details,
            subStepTypeId: 1,
            type: "string",
            operations: ["=", "startsWith", "endsWith", "contains"],
            suggest: "none",
            modelValueParameter: "itemName",
            modelValueComparator: "searchMethod",
            isComposed: true, // Details are one step
        },
        keyword: {
            stepTypeId: Resource.Keyword,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "itemName",
        },
        modifiedSince: {
            stepTypeId: Resource.Details,
            subStepTypeId: 2,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "modified_since",
            isComposed: true, // Details are one step
        },
        modifiedBy: {
            stepTypeId: Resource.Details,
            subStepTypeId: 3,
            type: "string",
            operations: ["="],
            suggest: "object",
            objectType: "contacts",
            modelValueParameter: "last_modified_by",
            isComposed: true, // Details are one step
        },
        resource: {
            stepTypeId: Resource.Resources,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "resources",
        },
        resourceSearch: {
            stepTypeId: Resource.Search,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Resource,
            modelValueParameter: "itemName",
        },
        category: {
            stepTypeId: Resource.Categories,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "resourceCategories",
        },
        relationship: {
            stepTypeId: Resource.Relationships,
            type: "embedded",
            operations: ["in", "notIn", "all"],
            suggest: "relationship",
        },
        custAtrb: {
            stepTypeId: Resource.CustomAttributes,
            type: "customAttribute",
            operations: ["in"],
            suggest: "attribute",
        },
        event: {
            stepTypeId: Resource.AssignedResources,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "events",
        },
        eventSearch: {
            stepTypeId: Resource.ResourceAssignments,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Event,
            modelValueParameter: "itemName",
        },
    },

    [Item.Ids.Organization]: {
        isFav: {
            stepTypeId: Organization.Favorites,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: BooleanOptions,
            modelValueParameter: "favorites",
            static: true,
        },
        name: {
            stepTypeId: Organization.Details,
            subStepTypeId: 1,
            type: "string",
            operations: ["=", "startsWith", "endsWith", "contains"],
            suggest: "none",
            modelValueParameter: "itemName",
            modelValueComparator: "searchMethod",
            isComposed: true, // Details are one step
        },
        formal: {
            stepTypeId: Organization.Details,
            subStepTypeId: 2,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "formal_name",
            modelValueComparator: "formal_name_search_method",
            isComposed: true, // Details are one step
        },
        keyword: {
            stepTypeId: Organization.Keyword,
            type: "string",
            operations: ["contains"],
            suggest: "none",
            modelValueParameter: "itemName",
        },
        key: {
            stepTypeId: Organization.Details,
            subStepTypeId: 3,
            type: "boolean",
            operations: ["="],
            suggest: "option",
            options: [{ label: "true", value: "true" }], // Value here is NOT "T"
            modelValueParameter: "s25_keys",
            skipIf: (step: Step) => !step?.step_param?.[0]?.s25_keys,
            isComposed: true, // Details are one step
        },
        code: {
            stepTypeId: Organization.Details,
            subStepTypeId: 4,
            type: "string",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "accounting_code",
            isComposed: true, // Details are one step
        },
        modifiedSince: {
            stepTypeId: Organization.Details,
            subStepTypeId: 5,
            type: "date",
            operations: ["="],
            suggest: "none",
            modelValueParameter: "modified_since",
            isComposed: true, // Details are one step
        },
        modifiedBy: {
            stepTypeId: Organization.Details,
            subStepTypeId: 6,
            type: "string",
            operations: ["="],
            suggest: "object",
            objectType: "contacts",
            modelValueParameter: "last_modified_by",
            isComposed: true, // Details are one step
        },
        orgSearch: {
            stepTypeId: Organization.Search,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Organization,
            modelValueParameter: "itemName",
        },
        org: {
            stepTypeId: Organization.Organizations,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "organizations",
        },
        address: {
            stepTypeId: Organization.Address,
            type: "stringList",
            operations: ["in"],
            suggest: "address",
        },
        category: {
            stepTypeId: Organization.Categories,
            type: "stringOrNumberList",
            operations: ["in", "notIn", "all"],
            suggest: "objects",
            objectType: "organizationCategories",
        },
        contact: {
            stepTypeId: Organization.Contacts,
            type: "embedded",
            operations: ["in"],
            suggest: "contactRole",
        },
        type: {
            stepTypeId: Organization.Types,
            type: "stringOrNumberList",
            operations: ["in", "notIn"],
            suggest: "objects",
            objectType: "organizationTypes",
        },
        custAtrb: {
            stepTypeId: Organization.CustomAttributes,
            type: "customAttribute",
            operations: ["in"],
            suggest: "attribute",
        },
        rating: {
            stepTypeId: Organization.Ratings,
            type: "stringOrNumberList",
            operations: ["in", "notIn"],
            suggest: "objects",
            objectType: "organizationRatings",
        },
        event: {
            stepTypeId: Organization.AssignedOrganizations,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "events",
        },
        eventSearch: {
            stepTypeId: Organization.OrganizationAssignments,
            type: "string",
            operations: ["="],
            suggest: "search",
            searchType: Ids.Event,
            modelValueParameter: "itemName",
        },
    },

    [Item.Ids.Task]: {
        state: {
            stepTypeId: Task.Any,
            type: "stringList",
            operations: ["in"],
            suggest: "options",
            options: [
                { label: "Outstanding", value: "outstanding" },
                { label: "Completed", value: "completed" },
                { label: "Denied", value: "denied" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Unread", value: "unread" },
            ],
        },
        type: {
            stepTypeId: Task.Any,
            type: "stringList",
            operations: ["in"],
            suggest: "options",
            options: [
                { label: "To Do", value: "todo" },
                { label: "vCalendar", value: "vcal" },
                { label: "Authorization", value: "authorization" },
                { label: "Notification", value: "fyi" },
                { label: "Assignment", value: "assignment" },
            ],
        },
        date: {
            stepTypeId: Task.Any,
            type: "dateRange",
            operations: ["between"],
            suggest: "none",
        },
        assignedTo: {
            stepTypeId: Task.Any,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "contacts",
        },
        assignedFrom: {
            stepTypeId: Task.Any,
            type: "stringOrNumberList",
            operations: ["in"],
            suggest: "objects",
            objectType: "contacts",
        },
    },
};

const Terms: any = {
    [Item.Ids.Event]: {
        isFav: {
            stepTypeId: 10,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Is Favorite",
            termValue: null,
            hasSuggestions: true,
        },
        name: {
            stepTypeId: 100,
            subTypeId: 1,
            type: "string",
            ops: ["=", "startsWith", "endsWith", "contains"],
            display: "Event Name",
            termParamMap: {
                "=": "is",
                contains: "contains",
                startsWith: "starts with",
                endsWith: "ends with",
            },
            termParam: "searchMethod",
            termValue: "itemName",
        },
        title: {
            stepTypeId: 100,
            subTypeId: 2,
            type: "string",
            ops: ["contains"],
            display: "Event Title",
            termParamMap: {
                contains: "contains",
            },
            termParam: "searchMethod",
            termValue: "event_title",
        },
        ref: {
            stepTypeId: 100,
            subTypeId: 3,
            type: "string",
            ops: ["="],
            display: "Event Reference",
            termValue: "reference_number",
        },
        structure: {
            stepTypeId: 100,
            subTypeId: 4,
            type: "string",
            ops: ["="],
            display: "Event Structure",
            termValue: "event_structure_element_type",
            hasSuggestions: true,
            valueMap: {
                //essentially listMap but for a single value property
                Event: "E",
                Folder: "F",
                Cabinet: "C",
            },
        },
        earliestStart: {
            stepTypeId: 100,
            subTypeId: 5,
            type: "date",
            ops: ["="],
            display: "Earliest Start Date",
            termValue: "start_dt",
        },
        latestEnd: {
            stepTypeId: 100,
            subTypeId: 6,
            type: "date",
            ops: ["="],
            display: "Latest End Date",
            termValue: "end_dt",
        },
        modifiedSince: {
            stepTypeId: 100,
            subTypeId: 7,
            type: "date",
            ops: ["="],
            display: "Modified Since",
            termValue: "modified_since",
        },
        modifiedBy: {
            stepTypeId: 100,
            subTypeId: 8,
            type: "string",
            ops: ["="],
            display: "Modified By",
            termValue: "last_modified_by",
            hasSuggestions: true,
        },
        alienUID: {
            stepTypeId: 100,
            subTypeId: 9,
            type: "string",
            ops: ["="],
            display: "Alien UID",
            termValue: "alien_uid",
        },
        cabinet: {
            stepTypeId: 105,
            type: "number",
            ops: ["in"],
            display: "Event Cabinet",
            termValue: "itemId",
            hasSuggestions: true,
        },
        folder: {
            stepTypeId: 104,
            type: "number",
            ops: ["in"],
            display: "Event Folder",
            termValue: "itemId",
            hasSuggestions: true,
        },
        type: {
            stepTypeId: 102,
            type: "number",
            ops: ["in", "notIn"],
            display: "Event Types",
            termValue: "itemId",
            hasSuggestions: true,
        },
        state: {
            stepTypeId: 101,
            type: "number",
            ops: ["in"],
            display: "Event States",
            termValue: "itemId",
            hasSuggestions: true,
        },
        keyword: {
            stepTypeId: 145,
            type: "string",
            ops: ["contains"],
            display: "Event Keyword",
            simpleText: true,
            termValue: "itemName",
        },
        profileCode: {
            stepTypeId: 146,
            type: "profileCode",
            ops: ["in"],
            display: "Meeting Pattern",
            simpleText: false,
            hasSuggestions: false,
            termValue: "profile_code",
        },
        standardSchedules: {
            stepTypeId: 148,
            type: "standardSchedule",
            ops: ["in", "notIn"],
            display: "Standard Schedules",
            simpleText: false,
            hasSuggestions: true,
            termValue: "standard_schedule_id",
        },
        org: {
            stepTypeId: 110,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Event Organizations",
            termValue: "itemId",
            hasSuggestions: true,
        },
        contact: {
            stepTypeId: 111,
            type: "number",
            ops: ["in"],
            display: "Event Contacts",
            termValue: "itemId",
            hasSuggestions: true,
            hasEmbedded: { name: "role", model: "contact_role_id" },
        },
        event: {
            stepTypeId: 107,
            type: "number",
            ops: ["in"],
            display: "Events",
            termValue: "itemId",
            hasSuggestions: true,
        },
        category: {
            stepTypeId: 120,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Categories",
            termValue: "itemId",
            hasSuggestions: true,
        },
        requirement: {
            stepTypeId: 130,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Requirements",
            termValue: "itemId",
            hasSuggestions: true,
        },
        location: {
            stepTypeId: 163,
            type: "number",
            ops: ["in", "all"],
            display: "Locations",
            termValue: "itemId",
            hasSuggestions: true,
        },
        locationPreferences: {
            stepTypeId: 165,
            type: "number",
            ops: ["in", "all"],
            display: "Preferred Locations",
            termValue: "itemId",
            hasSuggestions: true,
        },
        resource: {
            stepTypeId: 168,
            type: "number",
            ops: ["in", "all"],
            display: "Resources",
            termValue: "itemId",
            hasSuggestions: true,
        },
        eventSearch: {
            stepTypeId: 108,
            type: "number",
            ops: ["="],
            display: "Event Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        eventExcludeSearch: {
            stepTypeId: 192,
            type: "number",
            ops: ["="],
            display: "Event Search Exclude",
            termValue: "itemId",
            hasSuggestions: true,
        },
        orgSearch: {
            stepTypeId: 113,
            type: "number",
            ops: ["="],
            display: "Organization Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        resourceSearch: {
            stepTypeId: 167,
            type: "number",
            ops: ["="],
            display: "Resource Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        locationSearch: {
            stepTypeId: 162,
            type: "number",
            ops: ["="],
            display: "Location Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        hasRelated: {
            stepTypeId: 20,
            subTypeId: 1,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Has related events",
            termValue: null, //value is set implicitly based on defaults for sub type id
            hasSuggestions: true,
            choiceMap: { attr: "related_event_type_id", value: "11" },
        },
        hasBound: {
            stepTypeId: 20,
            subTypeId: 2,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Has bound events",
            termValue: null, //value is set implicitly based on defaults for sub type id
            hasSuggestions: true,
            choiceMap: { attr: "related_event_type_id", value: "12" },
        },
        vCalNeedsLocation: {
            stepTypeId: 174,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Outstanding Needs Location vCalendar To Dos",
            termValue: null,
            hasSuggestions: true,
        },
        vCalExceedsHeadCount: {
            stepTypeId: 175,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Outstanding Exceeds Headcount vCalendar To Dos",
            termValue: null,
            hasSuggestions: true,
        },
        vCalExport: {
            stepTypeId: 176,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Outstanding Export vCalendar To Dos",
            termValue: null,
            hasSuggestions: true,
        },
        occDate: {
            stepTypeId: 140,
            type: "date",
            ops: ["between"],
            display: "Occurrence Dates",
            termIndexValueMap: {
                first: { value: "from_dt", default: "2001-01-01T:00:00" },
                second: { value: "until_dt", default: "2100-12-31T23:59" },
            },
            hasFromUntil: true,
            consolidation: {
                occTime: {
                    params: [["occurrence_start_dt", "occurrence_end_dt"]],
                },
                daysOfWeek: {
                    //a step can have many params; for ALL consolidation entries the params are fixed such that param 0 is always for some semantic purpose
                    //and param 1 is a different one, but always for the same semantic purpose, and so on...
                    params: [
                        //param properties
                        ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
                    ],
                },
            },
            skipIf: function (step: any) {
                return (
                    step &&
                    step.step_param &&
                    step.step_param.length &&
                    /2001-01-01.*/.test(step.step_param[0].from_dt) &&
                    /2100-12-31.*/.test(step.step_param[0].until_dt)
                );
            },
        },
        occTime: {
            stepTypeId: 140,
            type: "time",
            ops: ["between"],
            display: "Occurrence Times",
            termIndexValueMap: {
                first: { value: "occurrence_start_dt", default: "00:00" },
                second: { value: "occurrence_end_dt", default: "23:59" },
            },
            consolidation: {
                occDate: {
                    params: [["from_dt", "until_dt"]],
                },
                daysOfWeek: {
                    //a step can have many params; for ALL consolidation entries the params are fixed such that param 0 is always for some semantic purpose
                    //and param 1 is a different one, but always for the same semantic purpose, and so on...
                    params: [
                        //param properties
                        ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
                    ],
                },
            },
            skipIf: function (step: any) {
                return (
                    step &&
                    step.step_param &&
                    step.step_param.length &&
                    /^00:00.*/.test(step.step_param[0].occurrence_start_dt) &&
                    /^23:59.*/.test(step.step_param[0].occurrence_end_dt)
                );
            },
        },
        daysOfWeek: {
            stepTypeId: 140,
            type: "string",
            multiChoices: [
                { txt: "M", val: "M", type: "string", noId: true },
                { txt: "T", val: "T", type: "string", noId: true },
                { txt: "W", val: "W", type: "string", noId: true },
                { txt: "R", val: "R", type: "string", noId: true },
                { txt: "F", val: "F", type: "string", noId: true },
                { txt: "S", val: "S", type: "string", noId: true },
                { txt: "U", val: "U", type: "string", noId: true },
            ],
            ops: ["in"],
            display: "Occurrence Days of the Week",
            hasSuggestions: true,
            choiceMap: {
                M: "monday",
                T: "tuesday",
                W: "wednesday",
                R: "thursday",
                F: "friday",
                S: "saturday",
                U: "sunday",
            },
            consolidation: {
                occDate: {
                    params: [["from_dt", "until_dt"]],
                },
                occTime: {
                    params: [["occurrence_start_dt", "occurrence_end_dt"]],
                },
            },
            skipIf: function (step: any) {
                return (
                    step &&
                    step.step_param &&
                    step.step_param.length &&
                    step.step_param[0].monday === "T" &&
                    step.step_param[0].tuesday === "T" &&
                    step.step_param[0].wednesday === "T" &&
                    step.step_param[0].thursday === "T" &&
                    step.step_param[0].friday === "T" &&
                    step.step_param[0].saturday === "T" &&
                    step.step_param[0].sunday === "T" &&
                    //if we type daysOfWeek in(...all days of week...) then switch to UI, other values (from/until/occ start/occ end) will be defaulted,
                    //then switch back, since all are now default values with days of week being all and from/until/occ start/occ end being defaulted, then ALL
                    //will be skipped! Prevent this by keeping daysOfWeek at least if the others are default
                    (!/2001-01-01.*/.test(step.step_param[0].from_dt) ||
                        !/2100-12-31.*/.test(step.step_param[0].until_dt) ||
                        !/^00:00.*/.test(step.step_param[0].occurrence_start_dt) ||
                        !/^23:59.*/.test(step.step_param[0].occurrence_end_dt))
                );
            },
        },
        expected: {
            stepTypeId: 141,
            subTypeId: 1,
            type: "number",
            ops: ["between"],
            display: "Expected",
            termIndexValueMap: {
                first: { value: "expected_head_count_gte", default: 0 },
                second: { value: "expected_head_count_lte", default: 999 },
            },
            consolidation: {
                registered: {
                    //a step can have many params; for ALL consolidation entries the params are fixed such that param 0 is always for some semantic purpose
                    //and param 1 is a different one, but always for the same semantic purpose, and so on...
                    params: [
                        //param properties
                        ["registered_head_count_gte", "registered_head_count_lte"],
                    ],
                },
            },
            skipIf: function (step: any) {
                return (
                    step &&
                    step.step_param &&
                    step.step_param.length &&
                    ((isNaN(parseInt(step.step_param[0].expected_head_count_gte)) &&
                        isNaN(parseInt(step.step_param[0].expected_head_count_lte))) ||
                        (parseInt(step.step_param[0].expected_head_count_gte) === 0 &&
                            parseInt(step.step_param[0].expected_head_count_lte) === 999))
                );
            },
        },
        registered: {
            stepTypeId: 141,
            subTypeId: 2,
            type: "number",
            ops: ["between"],
            display: "Registered",
            termIndexValueMap: {
                first: { value: "registered_head_count_gte", default: 0 },
                second: { value: "registered_head_count_lte", default: 999 },
            },
            skipIf: function (step: any) {
                return (
                    step &&
                    step.step_param &&
                    step.step_param.length &&
                    ((isNaN(parseInt(step.step_param[0].registered_head_count_gte)) &&
                        isNaN(parseInt(step.step_param[0].registered_head_count_lte))) ||
                        (parseInt(step.step_param[0].registered_head_count_gte) === 0 &&
                            parseInt(step.step_param[0].registered_head_count_lte) === 999))
                );
            },
        },
        segmentName: {
            stepTypeId: 141,
            subTypeId: 3,
            type: "string",
            ops: ["="],
            display: "Segment Name",
            termValue: "reservation_name",
        },
        createDt: {
            stepTypeId: 143,
            type: "date",
            ops: [">="], //>=
            display: "Create Date",
            termValue: "from_dt",
            hasRelativeDate: true,
            consolidation: {
                createTime: {
                    params: [["start_time"]],
                },
                skipIf: function (step: any) {
                    return (
                        step &&
                        step.step_param &&
                        step.step_param.length &&
                        /^00:00.*/.test(step.step_param[0].start_time)
                    );
                },
            },
        },
        createTime: {
            stepTypeId: 143,
            type: "time",
            ops: [">="],
            display: "Create Time",
            termValue: "start_time",
            termIndexValueMap: {
                first: { value: "start_time", default: "00:00:00" },
            },
            consolidation: {
                createDate: {
                    params: [["from_dt"]],
                },
            },
            skipIf: function (step: any) {
                return (
                    step && step.step_param && step.step_param.length && /^00:00.*/.test(step.step_param[0].start_time)
                );
            },
        },
        missingLocationAssn: {
            stepTypeId: 160,
            type: "date",
            ops: ["between"],
            display: "Missing Location Assignments",
            termIndexValueMap: {
                first: { value: "from_dt", default: "today" },
                second: { value: "until_dt", default: "today" },
            },
            hasFromUntil: true,
        },
        custAtrb: {
            stepTypeId: 150,
            type: "number",
            ops: ["in"],
            display: "Custom Attribute",
            hasSuggestions: true,
            isCustAtrb: true,
            isCompleteReset: true,
            forceAsNumber: true,
            needsId: [
                true,
                false,
                false,
                function (tokens: any, i: any, QLUtil: any) {
                    return ["1", "2", "3", "4", "6"].indexOf(i > 0 ? QLUtil.toStr(tokens[i - 1].itemName) : null) > -1;
                },
            ],
            listMap: [
                { model: "cust_atrb_id", val: "value", modelName: "cust_atrb_name", txt: "itemName" },
                {
                    model: "relationship_type_id",
                    val: "itemName",
                    modelName: "relationship_type_name",
                    id2Txt: {
                        1150: "is True",
                        2150: "is False",
                        157: "exists",
                        156: "does not exist",
                        150: "is equal to",
                        158: "contains",
                        153: "starts with",
                        154: "is earlier than or equal to",
                        155: "is later than or equal to",
                        151: "is less than or equal to",
                        152: "is greater than or equal to",
                    },
                },
                { model: "cust_atrb_type", val: "itemName" },
                { model: "itemId", mixedType: true },
            ],
        },
    },

    [Item.Ids.Location]: {
        isFav: {
            stepTypeId: 10,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Is Favorite",
            termValue: null,
            hasSuggestions: true,
        },
        name: {
            stepTypeId: 400,
            subTypeId: 1,
            type: "string",
            ops: ["=", "startsWith", "endsWith", "contains"],
            display: "Location Name",
            termParamMap: {
                "=": "is",
                contains: "contains",
                startsWith: "starts with",
                endsWith: "ends with",
            },
            termParam: "searchMethod",
            termValue: "itemName",
        },
        formal: {
            stepTypeId: 400,
            subTypeId: 2,
            type: "string",
            ops: ["contains"],
            display: "Formal Location Name",
            termParamMap: {
                contains: "contains",
            },
            termParam: "searchMethod",
            termValue: "formal_name",
        },
        keyword: {
            stepTypeId: 445,
            type: "string",
            ops: ["contains"],
            display: "Location Keyword",
            simpleText: true,
            termValue: "itemName",
        },
        maxCap: {
            stepTypeId: 400,
            subTypeId: 3,
            type: "number",
            ops: ["="],
            display: "Max Capacity",
            termValue: "max_capacity",
        },
        minCap: {
            stepTypeId: 400,
            subTypeId: 4,
            type: "number",
            ops: ["="],
            display: "Min Capacity",
            termValue: "min_capacity",
        },
        fill: {
            stepTypeId: 400,
            subTypeId: 5,
            type: "number",
            ops: ["="],
            display: "Fill Ratio",
            termValue: "fill_ratio",
        },
        modifiedSince: {
            stepTypeId: 400,
            subTypeId: 6,
            type: "date",
            ops: ["="],
            display: "Modified Since",
            termValue: "modified_since",
        },
        modifiedBy: {
            stepTypeId: 400,
            subTypeId: 7,
            type: "string",
            ops: ["="],
            display: "Modified By",
            termValue: "last_modified_by",
            hasSuggestions: true,
        },
        location: {
            stepTypeId: 407,
            type: "number",
            ops: ["in"],
            display: "Locations",
            termValue: "itemId",
            hasSuggestions: true,
        },
        locationSearch: {
            stepTypeId: 405,
            type: "number",
            ops: ["="],
            display: "Location Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        partition: {
            stepTypeId: 423,
            type: "number",
            ops: ["in", "notIn"],
            display: "Partitions",
            termValue: "itemId",
            hasSuggestions: true,
        },
        feature: {
            stepTypeId: 410,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Partitions",
            termValue: "itemId",
            hasSuggestions: true,
        },
        category: {
            stepTypeId: 420,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Categories",
            termValue: "itemId",
            hasSuggestions: true,
        },
        layout: {
            stepTypeId: 430,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Layouts",
            termValue: "itemId",
            hasSuggestions: true,
        },
        relationship: {
            stepTypeId: 460,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Relationships",
            termValue: "itemId",
            hasSuggestions: true,
            removeDefaultParam: true,
            hasEmbedded: { name: "relationship", model: "relationship_type_id" },
            cascadeEmbedded: true,
        },
        custAtrb: {
            stepTypeId: 450,
            type: "number",
            ops: ["in"],
            display: "Custom Attribute",
            hasSuggestions: true,
            isCustAtrb: true,
            isCompleteReset: true,
            forceAsNumber: true,
            needsId: [
                true,
                false,
                false,
                function (tokens: any, i: any, QLUtil: any) {
                    return ["1", "2", "3", "4", "6"].indexOf(i > 0 ? QLUtil.toStr(tokens[i - 1].itemName) : null) > -1;
                },
            ],
            listMap: [
                { model: "cust_atrb_id", val: "value", modelName: "cust_atrb_name", txt: "itemName" },
                {
                    model: "relationship_type_id",
                    val: "itemName",
                    modelName: "relationship_type_name",
                    id2Txt: {
                        1450: "is True",
                        2450: "is False",
                        457: "exists",
                        456: "does not exist",
                        450: "is equal to",
                        458: "contains",
                        453: "starts with",
                        454: "is earlier than or equal to",
                        455: "is later than or equal to",
                        451: "is less than or equal to",
                        452: "is greater than or equal to",
                    },
                },
                { model: "cust_atrb_type", val: "itemName" },
                { model: "itemId", mixedType: true },
            ],
        },
        event: {
            stepTypeId: 471,
            type: "number",
            ops: ["in"],
            display: "Events",
            termValue: "itemId",
            hasSuggestions: true,
        },
        eventSearch: {
            stepTypeId: 470,
            type: "number",
            ops: ["="],
            display: "Event Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        includeRelated: {
            stepTypeId: 20,
            type: "number",
            multiChoices: [
                { txt: "Also Assign", val: "4", type: "string" },
                { txt: "Blocked By", val: "3", type: "string" },
                { txt: "Close To", val: "2", type: "string" },
                { txt: "Divides Into", val: "5", type: "string" },
                { txt: "Subdivision Of", val: "1", type: "string" },
            ],
            termValue: "itemId",
            ops: ["in"],
            display: "Related Locations",
            isCompleteReset: true,
            hasSuggestions: true,
        },
    },

    [Item.Ids.Resource]: {
        isFav: {
            stepTypeId: 10,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Is Favorite",
            termValue: null,
            hasSuggestions: true,
        },
        name: {
            stepTypeId: 600,
            subTypeId: 1,
            type: "string",
            ops: ["=", "startsWith", "endsWith", "contains"],
            display: "Resource Name",
            termParamMap: {
                "=": "is",
                contains: "contains",
                startsWith: "starts with",
                endsWith: "ends with",
            },
            termParam: "searchMethod",
            termValue: "itemName",
        },
        keyword: {
            stepTypeId: 645,
            type: "string",
            ops: ["contains"],
            display: "Resource Keyword",
            simpleText: true,
            termValue: "itemName",
        },
        modifiedSince: {
            stepTypeId: 600,
            subTypeId: 2,
            type: "date",
            ops: ["="],
            display: "Modified Since",
            termValue: "modified_since",
        },
        modifiedBy: {
            stepTypeId: 600,
            subTypeId: 3,
            type: "string",
            ops: ["="],
            display: "Modified By",
            termValue: "last_modified_by",
            hasSuggestions: true,
        },
        resource: {
            stepTypeId: 607,
            type: "number",
            ops: ["in"],
            display: "Resources",
            termValue: "itemId",
            hasSuggestions: true,
        },
        resourceSearch: {
            stepTypeId: 605,
            type: "number",
            ops: ["="],
            display: "Resource Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        category: {
            stepTypeId: 620,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Categories",
            termValue: "itemId",
            hasSuggestions: true,
        },
        relationship: {
            stepTypeId: 660,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Relationships",
            termValue: "itemId",
            hasSuggestions: true,
            removeDefaultParam: true,
            hasEmbedded: { name: "relationship", model: "relationship_type_id" },
            cascadeEmbedded: true,
        },
        custAtrb: {
            stepTypeId: 650,
            type: "number",
            ops: ["in"],
            display: "Custom Attribute",
            hasSuggestions: true,
            isCustAtrb: true,
            isCompleteReset: true,
            forceAsNumber: true,
            needsId: [
                true,
                false,
                false,
                function (tokens: any, i: any, QLUtil: any) {
                    return ["1", "2", "3", "4", "6"].indexOf(i > 0 ? QLUtil.toStr(tokens[i - 1].itemName) : null) > -1;
                },
            ],
            listMap: [
                { model: "cust_atrb_id", val: "value", modelName: "cust_atrb_name", txt: "itemName" },
                {
                    model: "relationship_type_id",
                    val: "itemName",
                    modelName: "relationship_type_name",
                    id2Txt: {
                        1650: "is True",
                        2650: "is False",
                        657: "exists",
                        656: "does not exist",
                        650: "is equal to",
                        658: "contains",
                        653: "starts with",
                        654: "is earlier than or equal to",
                        655: "is later than or equal to",
                        651: "is less than or equal to",
                        652: "is greater than or equal to",
                    },
                },
                { model: "cust_atrb_type", val: "itemName" },
                { model: "itemId", mixedType: true },
            ],
        },
        event: {
            stepTypeId: 671,
            type: "number",
            ops: ["in"],
            display: "Events",
            termValue: "itemId",
            hasSuggestions: true,
        },
        eventSearch: {
            stepTypeId: 670,
            type: "number",
            ops: ["="],
            display: "Event Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
    },

    [Item.Ids.Organization]: {
        isFav: {
            stepTypeId: 10,
            type: "boolean",
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            ops: ["="],
            display: "Is Favorite",
            termValue: null,
            hasSuggestions: true,
        },
        name: {
            stepTypeId: 200,
            subTypeId: 1,
            type: "string",
            ops: ["=", "startsWith", "endsWith", "contains"],
            display: "Organization Name",
            termParamMap: {
                "=": "is",
                contains: "contains",
                startsWith: "starts with",
                endsWith: "ends with",
            },
            termParam: "searchMethod",
            termValue: "itemName",
        },
        formal: {
            stepTypeId: 200,
            subTypeId: 2,
            type: "string",
            ops: ["contains"],
            display: "Formal Organization Name",
            termParamMap: {
                contains: "contains",
            },
            termParam: "searchMethod",
            termValue: "formal_name",
        },
        keyword: {
            stepTypeId: 245,
            type: "string",
            ops: ["contains"],
            display: "Organization Keyword",
            simpleText: true,
            termValue: "itemName",
        },
        key: {
            //note: cannot have numbers in term names
            stepTypeId: 200,
            subTypeId: 3,
            choices: [{ txt: "true", val: "true", type: "boolean", noId: true }],
            type: "boolean",
            ops: ["="],
            display: "S25 Key",
            termValue: "s25_keys",
            hasSuggestions: true,
        },
        code: {
            stepTypeId: 200,
            subTypeId: 4,
            type: "string",
            ops: ["="],
            display: "Accounting Code",
            termValue: "accounting_code",
        },
        modifiedSince: {
            stepTypeId: 200,
            subTypeId: 5,
            type: "date",
            ops: ["="],
            display: "Modified Since",
            termValue: "modified_since",
        },
        modifiedBy: {
            stepTypeId: 200,
            subTypeId: 6,
            type: "string",
            ops: ["="],
            display: "Modified By",
            termValue: "last_modified_by",
            hasSuggestions: true,
        },
        orgSearch: {
            stepTypeId: 205,
            type: "number",
            ops: ["="],
            display: "Organization Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
        org: {
            stepTypeId: 207,
            type: "number",
            ops: ["in"],
            display: "Organizations",
            termValue: "itemId",
            hasSuggestions: true,
        },
        address: {
            stepTypeId: 240,
            type: "number",
            ops: ["in"],
            display: "Address",
            hasSuggestions: true,
            isCompleteReset: true,
            forceAsNumber: true,
            listMap: [
                {
                    model: "address_type_id",
                    val: "itemName",
                    id2Txt: { "-1": "(none)", "1": "Administration", "2": "Billing" },
                },
                { model: "address", val: "itemName" },
                { model: "city", val: "itemName" },
                { model: "zip", val: "itemName" },
                { model: "country", val: "itemName" },
                { model: "phone", val: "itemName" },
                { model: "fax", val: "itemName" },
            ],
        },
        category: {
            stepTypeId: 220,
            type: "number",
            ops: ["in", "notIn", "all"],
            display: "Categories",
            termValue: "itemId",
            hasSuggestions: true,
        },
        contact: {
            stepTypeId: 210,
            type: "number",
            ops: ["in"],
            display: "Organization Contacts",
            termValue: "itemId",
            hasSuggestions: true,
            hasEmbedded: { name: "role", model: "contact_role_id" },
        },
        type: {
            stepTypeId: 230,
            type: "number",
            ops: ["in", "notIn"],
            display: "Organization Types",
            termValue: "itemId",
            hasSuggestions: true,
        },
        custAtrb: {
            stepTypeId: 250,
            type: "number",
            ops: ["in"],
            display: "Custom Attribute",
            hasSuggestions: true,
            isCustAtrb: true,
            isCompleteReset: true,
            forceAsNumber: true,
            needsId: [
                true,
                false,
                false,
                function (tokens: any, i: any, QLUtil: any) {
                    return ["1", "2", "3", "4", "6"].indexOf(i > 0 ? QLUtil.toStr(tokens[i - 1].itemName) : null) > -1;
                },
            ],
            listMap: [
                { model: "cust_atrb_id", val: "value", modelName: "cust_atrb_name", txt: "itemName" },
                {
                    model: "relationship_type_id",
                    val: "itemName",
                    modelName: "relationship_type_name",
                    id2Txt: {
                        1250: "is True",
                        2250: "is False",
                        257: "exists",
                        256: "does not exist",
                        250: "is equal to",
                        258: "contains",
                        253: "starts with",
                        254: "is earlier than or equal to",
                        255: "is later than or equal to",
                        251: "is less than or equal to",
                        252: "is greater than or equal to",
                    },
                },
                { model: "cust_atrb_type", val: "itemName" },
                { model: "itemId", mixedType: true },
            ],
        },
        rating: {
            stepTypeId: 280,
            type: "number",
            ops: ["in", "notIn"],
            display: "Ratings",
            termValue: "itemId",
            hasSuggestions: true,
        },
        event: {
            stepTypeId: 271,
            type: "number",
            ops: ["in"],
            display: "Events",
            termValue: "itemId",
            hasSuggestions: true,
        },
        eventSearch: {
            stepTypeId: 270,
            type: "number",
            ops: ["="],
            display: "Event Search",
            termValue: "itemId",
            hasSuggestions: true,
        },
    },

    [Item.Ids.Task]: {
        state: {
            stepTypeId: 1000,
            type: "string",
            ops: ["in"],
            termValue: "itemId",
            multiChoices: [
                { txt: "Outstanding", val: "outstanding", type: "string" },
                { txt: "Completed", val: "completed", type: "string" },
                { txt: "Denied", val: "denied", type: "string" },
                { txt: "Cancelled", val: "cancelled", type: "string" },
                { txt: "Unread", val: "unread", type: "string" },
            ],
            composeStepParam: function (step: any) {
                var params = [];
                step.states.outstanding && params.push({ itemName: "Outstanding", itemId: "outstanding" });
                step.states.completed && params.push({ itemName: "Completed", itemId: "completed" });
                step.states.denied && params.push({ itemName: "Denied", itemId: "denied" });
                step.states.cancelled && params.push({ itemName: "Cancelled", itemId: "cancelled" });
                step.states.unread && params.push({ itemName: "Unread", itemId: "unread" });
                return params;
            },
            toStepDefault: function (step: any) {
                step.states.outstanding = false;
                step.states.completed = false;
                step.states.denied = false;
                step.states.cancelled = false;
                step.states.unread = false;
            },
            toStepFn: function (step: any, v: any) {
                if (v.value === "'Outstanding'") {
                    step.states.outstanding = true;
                } else if (v.value === "'Completed'") {
                    step.states.completed = true;
                } else if (v.value === "'Denied'") {
                    step.states.denied = true;
                } else if (v.value === "'Cancelled'") {
                    step.states.cancelled = true;
                } else if (v.value === "'Unread'") {
                    step.states.unread = true;
                }
            },
            display: "Task States",
            hasSuggestions: true,
        },
        type: {
            stepTypeId: 1000,
            type: "string",
            ops: ["in"],
            termValue: "itemId",
            multiChoices: [
                { txt: "To Do", val: "todo", type: "string" },
                { txt: "vCalendar", val: "vcal", type: "string" },
                { txt: "Authorization", val: "auth", type: "string" },
                { txt: "Notification", val: "notif", type: "string" },
                { txt: "Assignment", val: "assign", type: "string" },
            ],
            composeStepParam: function (step: any) {
                var params = [];
                step.types.todo && params.push({ itemName: "To Do", itemId: "todo" });
                step.types.vcal && params.push({ itemName: "vCalendar", itemId: "vcal" });
                step.types.authorization && params.push({ itemName: "Authorization", itemId: "authorization" });
                step.types.fyi && params.push({ itemName: "Notification", itemId: "fyi" });
                step.types.assignment && params.push({ itemName: "Assignment", itemId: "assignment" });
                return params;
            },
            toStepDefault: function (step: any) {
                step.types.todo = false;
                step.types.vcal = false;
                step.types.authorization = false;
                step.types.fyi = false;
                step.types.assignment = false;
            },
            toStepFn: function (step: any, v: any) {
                if (v.value === "'To Do'") {
                    step.types.todo = true;
                } else if (v.value === "'vCalendar'") {
                    step.types.vcal = true;
                } else if (v.value === "'Authorization'") {
                    step.types.authorization = true;
                } else if (v.value === "'Notification'") {
                    step.types.fyi = true;
                } else if (v.value === "'Assignment'") {
                    step.types.assignment = true;
                }
            },
            display: "Task Types",
            hasSuggestions: true,
        },
        date: {
            stepTypeId: 1000,
            type: "date",
            ops: ["between"],
            display: "Task Dates",
            composeStepParam: function (step: any) {
                return step.dateBean[0].step_param;
            },
            toStepFn: function (
                step: any,
                firstVal: any,
                secondVal: any,
                firstRelative: any,
                secondRelative: any,
                s25Util: any,
            ) {
                var newStep = step.dateBean[0];
                var firstDate = s25Util.date.parse(firstVal);
                var secondDate = s25Util.date.parse(secondVal);

                if (firstRelative) {
                    newStep.step_param[0].from_dt_num = s25Util.date.diffDays(new Date(), firstDate);
                    newStep.step_param[0].from_dt_type = "number";
                } else {
                    newStep.step_param[0].from_dt_date = firstDate;
                    newStep.step_param[0].from_dt_type = "date";
                }

                if (secondRelative) {
                    newStep.step_param[0].until_dt_num = s25Util.date.diffDays(new Date(), secondDate);
                    newStep.step_param[0].until_dt_type = "number";
                } else {
                    newStep.step_param[0].until_dt_date = secondDate;
                    newStep.step_param[0].until_dt_type = "date";
                }
            },
            hasFromUntil: true,
        },
        assignedTo: {
            stepTypeId: 1000,
            criteriaContextOverride: 111, //contacts context for search
            type: "number",
            ops: ["in"],
            display: "Assigned To Contacts",
            termValue: "itemId",
            hasSuggestions: true,
            composeStepParam: function (step: any) {
                return step.contactBeans[0].step_param;
            },
            toStepFn: function (step: any, v: any) {
                step.contactBeans[0].step_param.push({
                    itemName: v.itemName,
                    itemId: v.value,
                });
            },
        },
        assignedFrom: {
            stepTypeId: 1000,
            criteriaContextOverride: 111, //contacts context for search
            type: "number",
            ops: ["in"],
            display: "Assigned From Contacts",
            termValue: "itemId",
            hasSuggestions: true,
            composeStepParam: function (step: any) {
                return step.contactBeans[1].step_param;
            },
            toStepFn: function (step: any, v: any) {
                step.contactBeans[1].step_param.push({
                    itemName: v.itemName,
                    itemId: v.value,
                });
            },
        },
    },
};

export const S25QLConst = {
    QLPrefix: "::",
    KeywordTerm: { 1: "keyword", 4: "keyword", 6: "keyword", 2: "keyword", 10: "name" },
    KeywordOp: "contains",
    itemDescDelim: " --- ",
    stepNormMap: {
        103: 102,
        112: 110,
        121: 120,
        131: 130,
        [StepType.Event.ProfileCodeNone]: [StepType.Event.ProfileCode], //147:146

        411: 410,
        421: 420,
        424: 423,
        [StepType.Location.BuildingsExclude]: StepType.Location.Buildings,
        433: 430,
        461: 460,

        621: 620,
        661: 660,

        221: 220,
        231: 230,
        281: 280,
    } as any,
    readOnly: [106, 170, 171, 172, 173, 180, 181],
    dateSugar: ["today"],

    //yyyy-mm-dd, yyyy/mm/dd, mm-dd-yyyy, mm/dd/yyyy
    dateRegex:
        /^\d{4}[-/]\d{1,2}[-/]\d{1,2}(T\d{2}[:-]\d{2}([:-]\d{2}){0,1}){0,1}$|^\d{1,2}[-/]\d{1,2}[-/]\d{4}(T\d{2}[:-]\d{2}([:-]\d{2}){0,1}){0,1}$/,

    //hh:mm:MM, hh-mm-MM (these are 2-digit hour, 2-digit minute, 2-digit millisecond)
    timeRegex: /^\d{2}[:-]\d{2}([:-]\d{2}){0,1}$/,

    metaOps: ["and", "or"],
    comparisonOps: ["=", ">", "<", ">=", "<=", "in", "notIn", "all", "contains", "startsWith", "endsWith", "between"],
    ops: [
        "and",
        "or",
        "=",
        ">",
        "<",
        ">=",
        "<=",
        "(",
        ")",
        "in",
        "notIn",
        "all",
        "contains",
        "startsWith",
        "endsWith",
        "between",
        "+",
        "-",
    ],
    reservedWords: ["true", "false"],
    embeddedIds: ["role", "relationship"],
    hasDummy: ["between"],

    terms: Terms2,
    operatorModelMap: {
        "=": { op: "is" },
        startsWith: { op: "starts with" },
        endsWith: { op: "ends with" },
        notIn: { op: "notIn", qualifier: "-1" },
        in: { op: "in", qualifier: "1" },
        all: { op: "all", qualifier: "2" },
    } as Record<string, { op: string; qualifier?: NumericalString }>,
    customAttributeRelationshipIds: {
        "is True": 50,
        "is False": 50,
        "is equal to": 50,
        "is less than or equal to": 51,
        "is greater than or equal to": 52,
        "starts with": 53,
        "is earlier than or equal to": 54,
        "is later than or equal to": 55,
        "does not exist": 56,
        exists: 57,
        contains: 58,
    },
    customAttributeRelationshipMap: {
        "equal to": "is equal to",
        "is not null": "exists",
        "is null": "does not exist",
        "earlier than or equal to": "is earlier than or equal to",
        "later than or equal to": "is later than or equal to",
        "greater than or equal to": "is greater than or equal to",
        "less than or equal to": "is less than or equal to",
    } as any,
    noValueCustomAttributeTypes: new Set(["B", "I", "X"]),
    noValueCustomAttributeOperators: new Set(["exists", "does not exist"]),

    //terms by object type
    ...Terms,

    advancedUIOptions: {
        [Ids.Event]: [
            advancedUIOption("Add Group", -100),
            advancedUIOption("Your Starred Events", 10, null, "icon-event-fav"),
            advancedUIOption("General", null, null, null, [
                advancedUIOption("All General Options", StepType.Event.Details),
                advancedUIOption("Event Name", StepType.Event.Details, 1),
                advancedUIOption("Event Title", StepType.Event.Details, 2),
                advancedUIOption("Reference", StepType.Event.Details, 3),
                advancedUIOption("Structure Element", StepType.Event.Details, 4),
                advancedUIOption("Earliest Start Date", StepType.Event.Details, 5),
                advancedUIOption("Latest End Date", StepType.Event.Details, 6),
                advancedUIOption("Modified Since", StepType.Event.Details, 7),
                advancedUIOption("Last Modified By", StepType.Event.Details, 8),
                advancedUIOption("Alien UID", StepType.Event.Details, 9),
            ]),
            advancedUIOption("Keyword", StepType.Event.Keyword),
            advancedUIOption("Events", null, null, "icon-event hasIcon", [
                advancedUIOption("Specific Events", StepType.Event.Events),
                advancedUIOption("Events (Via Search)", StepType.Event.EventSearch),
                advancedUIOption("Event Exclude (Via Search)", StepType.Event.EventSearchExclude),
            ]),
            advancedUIOption("Folders", StepType.Event.Folders, null, "icon-folder"),
            advancedUIOption("Cabinets", StepType.Event.Cabinets, null, "icon-cabinet"),
            advancedUIOption("Create Date", StepType.Event.CreatedDate),
            advancedUIOption("Event Types", StepType.Event.Types),
            advancedUIOption("Event States", StepType.Event.States),
            advancedUIOption("Organizations", null, null, "icon-org hasIcon", [
                advancedUIOption("Specific Organizations", StepType.Event.Organizations),
                advancedUIOption("Organization Searches", StepType.Event.SponsoringOrganizations),
            ]),
            advancedUIOption("Contacts", StepType.Event.Contacts, null, "icon-contact"),
            advancedUIOption("Categories", StepType.Event.Categories),
            advancedUIOption("Requirements", StepType.Event.Requirements),
            advancedUIOption("Custom Attribute", StepType.Event.CustomAttributes),
            advancedUIOption("Occurrence Dates", StepType.Event.Occurrences),
            advancedUIOption("Meeting Pattern", StepType.Event.ProfileCode),
            advancedUIOption("Standard Schedules", StepType.Event.StandardSchedule),
            advancedUIOption("Segment Definitions", null, null, null, [
                advancedUIOption("Segment Name", StepType.Event.ReservationDetails, 3),
                advancedUIOption("Expected Head Count", StepType.Event.ReservationDetails, 1),
                advancedUIOption("Registered Head Count", StepType.Event.ReservationDetails, 2),
            ]),
            advancedUIOption("Assigned Locations", null, null, "icon-space hasIcon", [
                advancedUIOption("Specific Locations", StepType.Event.AssignedSpaces),
                advancedUIOption("Locations (Via Search)", StepType.Event.SpaceAssignments),
            ]),
            advancedUIOption("Preferred Locations", StepType.Event.SpacePreferences),
            advancedUIOption("Missing Location Assignments", StepType.Event.MissingSpaceAssignments),
            advancedUIOption("Assigned Resources", null, null, "icon-resource hasIcon", [
                advancedUIOption("Specific Resources", StepType.Event.AssignedResources),
                advancedUIOption("Resources (Via Search)", StepType.Event.ResourcesAssignments),
            ]),
            advancedUIOption("Outstanding vCalendar Tasks", null, null, null, [
                advancedUIOption("Needs Space", StepType.Event.OutstandingNeedsSpaceVCalendarToDos),
                advancedUIOption("Exceeds Head Count", StepType.Event.OutstandingExceedsHeadcountVCalendarToDos),
                advancedUIOption("Export", StepType.Event.OutstandingExportVCalendarToDos),
            ]),
            advancedUIOption("Has Related Events", StepType.Event.IncludeRelatedEvents, 1),
            advancedUIOption("Has Bound Events", StepType.Event.IncludeRelatedEvents, 2),
        ] as DropDownItem[],
        [Item.Ids.Location]: [
            advancedUIOption("Add Group", -400),
            advancedUIOption("Your Starred Locations", 10, null, "icon-space-fav"),
            advancedUIOption("General", null, null, null, [
                advancedUIOption("All General Options", StepType.Location.Details),
                advancedUIOption("Location Name", StepType.Location.Details, 1),
                advancedUIOption("Location Formal name", StepType.Location.Details, 2),
                advancedUIOption("Maximum Capacity", StepType.Location.Details, 3),
                advancedUIOption("Minimum Capacity", StepType.Location.Details, 4),
                advancedUIOption("Fill Ratio", StepType.Location.Details, 5),
                advancedUIOption("Modified Since", StepType.Location.Details, 6),
                advancedUIOption("Last Modified By", StepType.Location.Details, 7),
            ]),
            advancedUIOption("Keyword", StepType.Location.Keyword),
            advancedUIOption("Locations", null, null, "icon-space", [
                advancedUIOption("Specific Locations", StepType.Location.Spaces),
                advancedUIOption("Locations (Via Search)", StepType.Location.Search),
            ]),
            advancedUIOption("Buildings", StepType.Location.Buildings),
            advancedUIOption("Partitions", StepType.Location.Partitions),
            advancedUIOption("Features", StepType.Location.Features),
            advancedUIOption("Categories", StepType.Location.Categories),
            advancedUIOption("Layouts", StepType.Location.Layouts),
            advancedUIOption("Relationships", StepType.Location.SpaceRelationships),
            advancedUIOption("Custom Attribute", StepType.Location.CustomAttributes),
            advancedUIOption("Location Assignments", null, null, "icon-event", [
                advancedUIOption("Specific Events", StepType.Location.AssignedSpaces),
                advancedUIOption("Events (Via Search)", StepType.Location.SpaceAssignments),
            ]),
            advancedUIOption("Include Related Locations", StepType.Location.IncludeRelatedSpaces),
        ] as DropDownItem[],
        [Item.Ids.Organization]: [
            advancedUIOption("Add Group", -200),
            advancedUIOption("Your Starred Organizations", 10, null, "icon-org-fav"),
            advancedUIOption("General", null, null, null, [
                advancedUIOption("All General Options", StepType.Organization.Details),
                advancedUIOption("Organization Name", StepType.Organization.Details, 1),
                advancedUIOption("Organization Title", StepType.Organization.Details, 2),
                advancedUIOption("Schedule25 Key", StepType.Organization.Details, 3),
                advancedUIOption("Accounting Code", StepType.Organization.Details, 4),
                advancedUIOption("Modified Since", StepType.Organization.Details, 5),
                advancedUIOption("Last Modified By", StepType.Organization.Details, 6),
            ]),
            advancedUIOption("Keyword", StepType.Organization.Keyword),
            advancedUIOption("Organizations", null, null, "icon-org-fav", [
                advancedUIOption("Specific Organizations", StepType.Organization.Organizations),
                advancedUIOption("Organizations (Via Search)", StepType.Organization.Search),
            ]),
            advancedUIOption("Address", StepType.Organization.Address),
            advancedUIOption("Categories", StepType.Organization.Categories),
            advancedUIOption("Contacts", StepType.Organization.Contacts),
            advancedUIOption("Organization Types", StepType.Organization.Types),
            advancedUIOption("Custom Attributes", StepType.Organization.CustomAttributes),
            advancedUIOption("Ratings", StepType.Organization.Ratings),
            advancedUIOption("Organization Assignments", null, null, "icon-event", [
                advancedUIOption("Specific Events", StepType.Organization.AssignedOrganizations),
                advancedUIOption("Events (Via Search)", StepType.Organization.OrganizationAssignments),
            ]),
        ] as DropDownItem[],
        [Item.Ids.Resource]: [
            advancedUIOption("Add Group", -600),
            advancedUIOption("Your Starred Resources", 10, null, "icon-resource-fav"),
            advancedUIOption("General", null, null, null, [
                advancedUIOption("All General Options", StepType.Resource.Details),
                advancedUIOption("Resource Name", StepType.Resource.Details, 1),
                advancedUIOption("Modified Since", StepType.Resource.Details, 2),
                advancedUIOption("Last Modified By", StepType.Resource.Details, 3),
            ]),
            advancedUIOption("Keyword", StepType.Resource.Keyword),
            advancedUIOption("Resources", null, null, "icon-resource", [
                advancedUIOption("Specific Resources", StepType.Resource.Resources),
                advancedUIOption("Resources (Via Search)", StepType.Resource.Search),
            ]),
            advancedUIOption("Categories", StepType.Resource.Categories),
            advancedUIOption("Relationships", StepType.Resource.Relationships),
            advancedUIOption("Custom Attributes", StepType.Resource.CustomAttributes),
            advancedUIOption("Resource Assignments", null, null, "icon-event", [
                advancedUIOption("Specific Events", StepType.Resource.AssignedResources),
                advancedUIOption("Events (Via Search)", StepType.Resource.ResourceAssignments),
            ]),
        ] as DropDownItem[],
        [Item.Ids.Task]: [] as DropDownItem[],
    },

    advancedUIOptionName: {
        [Ids.Event]: {
            [StepType.Event.Favorites]: "Your Starred Events",
            [StepType.Event.Details]: "General",
            [StepType.Event.Keyword]: "Keyword",
            [StepType.Event.Events]: "Specific Events",
            [StepType.Event.EventSearch]: "Events (Via Search)",
            [StepType.Event.EventSearchExclude]: "Event Exclude (Via Search)",
            [StepType.Event.Folders]: "Folders",
            [StepType.Event.Cabinets]: "Cabinets",
            [StepType.Event.CreatedDate]: "Create Date",
            [StepType.Event.Types]: "Event Types",
            [StepType.Event.States]: "Event States",
            [StepType.Event.Organizations]: "Specific Organizations",
            [StepType.Event.SponsoringOrganizations]: "Organization Searches",
            [StepType.Event.Contacts]: "Contacts",
            [StepType.Event.Categories]: "Categories",
            [StepType.Event.Requirements]: "Requirements",
            [StepType.Event.CustomAttributes]: "Custom Attribute",
            [StepType.Event.Occurrences]: "Occurrence Dates",
            [StepType.Event.ReservationDetails]: "Segment Definitions",
            [StepType.Event.AssignedSpaces]: "Specific Locations",
            [StepType.Event.SpaceAssignments]: "Locations (Via Search)",
            [StepType.Event.SpacePreferences]: "Preferred Locations",
            [StepType.Event.MissingSpaceAssignments]: "Missing Location Assignments",
            [StepType.Event.AssignedResources]: "Specific Resources",
            [StepType.Event.ResourcesAssignments]: "Resources (Via Search)",
            [StepType.Event.OutstandingNeedsSpaceVCalendarToDos]: "Needs Space",
            [StepType.Event.OutstandingExceedsHeadcountVCalendarToDos]: "Exceeds Head Count",
            [StepType.Event.OutstandingExportVCalendarToDos]: "Export",
            [StepType.Event.IncludeRelatedEvents]: "Related Events",
            [StepType.Event.ProfileCode]: "Meeting Pattern",
            [StepType.Event.StandardSchedule]: "Standard Schedules",
        },
        [Item.Ids.Location]: {
            [StepType.Location.Favorites]: "Your Starred Locations",
            [StepType.Location.Details]: "General",
            [StepType.Location.Keyword]: "Keyword",
            [StepType.Location.Spaces]: "Specific Locations",
            [StepType.Location.Search]: "Locations (Via Search)",
            [StepType.Location.Buildings]: "Buildings",
            [StepType.Location.Partitions]: "Partitions",
            [StepType.Location.Features]: "Features",
            [StepType.Location.Categories]: "Categories",
            [StepType.Location.Layouts]: "Layouts",
            [StepType.Location.SpaceRelationships]: "Relationships",
            [StepType.Location.CustomAttributes]: "Custom Attribute",
            [StepType.Location.AssignedSpaces]: "Specific Events",
            [StepType.Location.SpaceAssignments]: "Events (Via Search)",
            [StepType.Location.IncludeRelatedSpaces]: "Include Related Locations",
        },
        [Item.Ids.Organization]: {
            [StepType.Organization.Favorites]: "Your Starred Organizations",
            [StepType.Organization.Details]: "General",
            [StepType.Organization.Keyword]: "Keyword",
            [StepType.Organization.Organizations]: "Specific Organizations",
            [StepType.Organization.Search]: "Organizations (Via Search)",
            [StepType.Organization.Address]: "Address",
            [StepType.Organization.Categories]: "Categories",
            [StepType.Organization.Contacts]: "Contacts",
            [StepType.Organization.Types]: "Organization Types",
            [StepType.Organization.CustomAttributes]: "Custom Attributes",
            [StepType.Organization.Ratings]: "Ratings",
            [StepType.Organization.AssignedOrganizations]: "Specific Events",
            [StepType.Organization.OrganizationAssignments]: "Events (Via Search)",
        },
        [Item.Ids.Resource]: {
            [StepType.Resource.Favorites]: "Your Starred Resources",
            [StepType.Resource.Details]: "General",
            [StepType.Resource.Keyword]: "Keyword",
            [StepType.Resource.Resources]: "Specific Resources",
            [StepType.Resource.Search]: "Resources (Via Search)",
            [StepType.Resource.Categories]: "Categories",
            [StepType.Resource.Relationships]: "Relationships",
            [StepType.Resource.CustomAttributes]: "Custom Attributes",
            [StepType.Resource.AssignedResources]: "Specific Events",
            [StepType.Resource.ResourceAssignments]: "Events (Via Search)",
        },
    } as any,

    simpleCriteria: {
        [Item.Ids.Event]: [
            { id: 14, searchCriteria: "eventCabinets", single: true, parameter: "cabinet_id" },
            { id: 13, searchCriteria: "organizations", parameter: "organization_id" },
            { id: 12, searchCriteria: "eventTypes", parameter: "event_type_id" },
            { id: 11, searchCriteria: "eventCategories", hasMatch: true, parameter: "category_id" },
            { id: 16, searchCriteria: "eventRoles", single: true, parameter: "role_id" },
            { id: 15, searchCriteria: "eventStates", parameter: "state" },
        ],
        [Item.Ids.Location]: [
            { id: 41, searchCriteria: "locationCategories", parameter: "category_id", hasMatch: true },
            { id: 42, searchCriteria: "locationFeatures", parameter: "feature_id", hasMatch: true },
            { id: 43, searchCriteria: "locationLayouts", parameter: "layout_id", hasMatch: true },
            {
                id: 44,
                custom: "Capacity",
                getParam: ({ min, max }: { min: number; max: number }) => {
                    return (!min ? "" : `&min_capacity=${min}`) + (!max ? "" : `&max_capacity=${max}`);
                },
            },
            { id: 45, searchCriteria: "buildings", parameter: "building_id", hasMatch: false }, // Buildings only support match any
        ],
        [Item.Ids.Organization]: [
            { id: 22, searchCriteria: "organizationTypes", parameter: "type_id" },
            { id: 21, searchCriteria: "organizationCategories", parameter: "category_id", hasMatch: true },
        ],
        [Item.Ids.Resource]: [
            { id: 61, searchCriteria: "resourceCategories", parameter: "category_id", hasMatch: true },
        ],
        [Item.Ids.Contact]: [] as any,
        [Item.Ids.Task]: [] as any,
    },

    simpleCriteriaStepTypeMap: {
        eventCabinets: { stepTypeId: 105 },
        eventCategories: { stepTypeId: 120 },
        organizations: { stepTypeId: 110 },
        eventRoles: { stepTypeId: 111 },
        eventStates: { stepTypeId: 101 },
        eventTypes: { stepTypeId: 102 },
        Capacity: { stepTypeId: 400, subStepTypeId: 8 },
        locationFeatures: { stepTypeId: 410 },
        locationLayouts: { stepTypeId: 430 },
        locationCategories: { stepTypeId: 420 },
        buildings: { stepTypeId: 425 },
        organizationTypes: { stepTypeId: 230 },
        organizationCategories: { stepTypeId: 220 },
        resourceCategories: { stepTypeId: 620 },
        standardSchedules: { stepTypeId: 148 },
    } as { [key: string]: { stepTypeId: number; subStepTypeId?: number } },
};

function advancedUIOption(
    itemName: string,
    stepTypeId: number,
    subStepTypeId?: number,
    iconClass?: string,
    items?: DropDownItem[],
): DropDownItem {
    return {
        itemId: `${itemName}&${stepTypeId}&${subStepTypeId}`,
        itemName,
        stepTypeId,
        subStepTypeId,
        iconClass,
        items,
    };
}
